import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import db from './db'
import storage from 'vue-localstorage'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueMatomo from 'vue-matomo'
import Language from './enums/Language'
import LanguageManager from './assets/lang/LanguageManager'
import CriaCertoPlugin from './plugin.js'

Vue.prototype.$db = db

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: process.env.VUE_APP_VERSION.split('-')[0],
  environment: process.env.VUE_APP_STAGE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', window.location.hostname, /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

Vue.use(storage)

Vue.use(storage, {
  name: 'ls',
  bind: true
})

Vue.use(VueMatomo, {
  host: 'https://hit.embrapa.io',
  siteId: process.env.VUE_APP_MATOMO_ID,
  router: router,
  preInitActions: [
    ['setCustomDimension', 1, process.env.VUE_APP_STAGE],
    ['setCustomDimension', 2, process.env.VUE_APP_VERSION]
  ]
})

export const bus = new Vue()
var lang = ''
var texts = {}
var settedLang = localStorage.language
console.log(settedLang)

switch (settedLang) {
case Language.pt:
  lang = Language.pt
  break
case Language.es:
  lang = Language.es
  break
case Language.en:
  lang = Language.en
  break
default:
  lang = Language.pt
}

texts = new LanguageManager(lang).get()

Vue.use(CriaCertoPlugin)

new Vue({
  router,
  vuetify,
  render: h => h(App),
  data: {
    trySync: true,
    language: lang,
    text: texts
  }
}).$mount('#app')
