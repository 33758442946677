import Vue from 'vue'
import Router from 'vue-router'
import Analytics from 'vue-analytics'

const options = [
  { path: '/', component: 'Simulation', name: 'Simulações', props: false },
  { path: '/login', component: 'Login', name: 'Login', props: false },
  { path: '/natural-mount-reports/:code', component: 'MountReport', name: 'Resultado Monta Natural', props: false },
  { path: '/iatf-rt-reports/:code', component: 'IatfRtReport', name: 'Resultado IATF + RT', props: false },
  { path: '/2-iatf-rt-reports/:code', component: 'DuasIatfRtReport', name: 'Resultado 2 IATF + RT', props: false },
  { path: '/3-iatf-reports/:code', component: 'TresIatfReport', name: 'Resultado 3 IATF', props: false },
  { path: '/compare', component: 'Compare', name: 'Comparar', props: false },
  { path: '/settings', component: 'Settings', name: 'Configurações', props: false },
  { path: '/about', component: 'About', name: 'Sobre', props: false },
  { path: '/natural-mount/:code', component: 'TypeNaturalMount', name: 'Monta Natural', props: true },
  { path: '/iatf-rt/:code', component: 'TypeIATFRT', name: 'IATF + RT', props: true },
  { path: '/2-iatf-rt/:code', component: 'Type2IATFRT', name: '2 IATF + RT', props: true },
  { path: '/3-iatf/:code', component: 'Type3IATF', name: '3 IATF', props: true }
]

const routes = options.map(route => {
  return {
    path: route.path,
    component: () => import(`@/pages/${route.component}.vue`),
    name: route.name,
    props: route.props
  }
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_PATH || '/',
  routes
})

Vue.use(Analytics, {
  id: process.env.VUE_APP_ANALYTICS,
  checkDuplicatedScript: true,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  },
  router
})

export default router
