import pt from './pt.json'
import en from './en.json'
import es from './es.json'
import Language from '../../enums/Language'

export default class LanguageManager {
  constructor (language) {
    this.language = language
  }

  get () {
    switch (this.language) {
    case Language.pt:
      return pt
    case Language.en:
      return en
    case Language.es:
      return es
    default:
      return pt
    }
  }
}
