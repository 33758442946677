<template>
  <v-app>
      <router-view></router-view>

      <update-wrapper></update-wrapper>
  </v-app>
</template>

<script>
import UpdateWrapper from './components/Update.vue'
export default {
  components: {
    UpdateWrapper
  },
  data () {
    return {
    }
  },
  localStorage: {
    user: {
      type: Object,
      default: {
        authenticated: false,
        name: '',
        email: '',
        picture: '',
        token: ''
      }
    },
    synchronized: {
      type: Number,
      default: 0
    },
    iosTryInstall: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: 'pt'
    }
  }
}
</script>
