import Language from './enums/Language'

export default {
  install: (Vue, options) => {
    Vue.prototype.toMoney = function (v, lang) {
      switch (lang) {
      case Language.pt:
        return v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      case Language.en:
        return v.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      case Language.es:
        return v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R', '')
      }
    }

    Vue.prototype.toDep = function (v, lang) {
      if (lang === Language.en) {
        return v.toFixed(2)
      }

      return v.toFixed(2).replace('.', ',')
    }

    Vue.prototype.toPregnancy = function (v, lang) {
      if (lang === Language.en) {
        if (v % 10 == 0){
          return v + '%'
        }
        else{
          return v.toFixed(1) + '%'
        }
        
      }

      if (v % 10 == 0){
        return v + '%'
      }
      else{
        return v.toFixed(1).replace('.', ',') + '%'
      }
    }
  }
}
